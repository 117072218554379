define('ember-intl/formatters/-base', ['exports', 'ember-intl/utils/array-to-hash', 'ember-intl/utils/links'], function (exports, _arrayToHash, _links) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  var FormatterBase = Ember.Object.extend({
    options: null,

    init: function init() {
      this._super();

      if (this.constructor === FormatterBase) {
        throw new Error('FormatHelper is an abstract class, can not be instantiated directly.');
      }

      this.options = (0, _arrayToHash.default)(this.constructor.supportedOptions);
    },
    filterSupporedOptions: function filterSupporedOptions(options) {
      if (!options) {
        return {};
      }

      var supportedOptions = {};
      var foundMatch = false;
      var camelizedKey = void 0;

      for (var key in options) {
        camelizedKey = Ember.String.camelize(key);
        if (this.options[camelizedKey]) {
          foundMatch = true;
          supportedOptions[camelizedKey] = options[key];
        }
      }

      if (foundMatch) {
        return supportedOptions;
      }
    },
    format: function format() {
      throw new Error('not implemented');
    },
    _format: function _format(value, formatterOptions, formatOptions, _ref) {
      var locale = _ref.locale;

      if (!locale) {
        throw new Error('No locale specified.  This is typically handled within routes/application.js.\n        Documentation: ' + _links.default.unsetLocale);
      }

      return Ember.get(this, 'formatter')(locale, formatterOptions).format(value, formatOptions);
    }
  });

  FormatterBase.reopenClass({
    supportedOptions: ['locale', 'format'],
    concatenatedProperties: ['supportedOptions']
  });

  exports.default = FormatterBase;
});